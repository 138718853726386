<template>
  <v-card class="bg-dark tw-m-10" outline>
    <v-toolbar flat>
        <v-btn
          icon
          small
          color="transparent"
          :to="{ name: 'Roles' }"
          class="hover:tw-bg-slate-300 elevation-5 tw-m-2 mx-2"
        >
          <v-icon color="black">mdi-chevron-left</v-icon>
        </v-btn>
        {{ viewRole.displayName }}
      <v-spacer />
      <v-text-field v-model="search" label="search here ..." />
      <v-dialog v-model="dialogDelete" v-if="userTemp" max-width="500px">
        <v-card>
          <v-card-title class="text-h5 tw-text-slate-600"
            >Remove {{ userTemp.firstName }} {{ userTemp.lastName }} from this role?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Cancel</v-btn
            >
            <v-btn color="red darken-1" text @click="deleteItemConfirm(userTemp.id)"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <AssignRoleUsersDialog :role="viewRole" />
    </v-toolbar>
    <v-card-text>
<!--      {{viewRole.usersList}}-->
      <v-data-table :headers="headers" :items="viewRole.usersList" :search="search">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title
              >Users
              <v-btn x-small @click="initialize" color="primary">
                <v-icon x-small>fa fa-refresh</v-icon>
                Refresh
              </v-btn>
              <v-divider inset vertical />
            </v-toolbar-title>
            <v-spacer />
            <v-btn small color="primary" @click="assignUsers()">
              <v-icon>mdi-account-plus</v-icon>
              Add Users
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <p class="my-3">NO DATA FOUND</p>
          <v-btn class="mb-3" color="primary" @click="initialize">
            Reset
          </v-btn>
        </template>
        <template v-slot:item.sn="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.fullName="{item}">
          {{ item.user.firstName?item.user.firstName:item.user.email }}
          {{ item.user.lastName }}

        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="removeFromRole(item.user)"
            title="Remove From Role"
            class="hover:!tw-text-rose-400"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AssignRoleUsersDialog from "@/components/RoleManagement/AssignRoleUsersDialog.vue";
export default {
  name: "role-view",
  data() {
    return {
      headers: [
        {
          text: "S/N",
          align: "start",
          filterable: false,
          value: "sn",
        },
        { text: "Full Name", value: "fullName" },
        { text: "Email", value: "user.email" },
        { text: "Actions", filterable: false, value: "actions" },
      ],
      search: "",
      roleUuid: "",
      dialogDelete: false,
      user: null,
      userTemp:null,
    };
  },
  async created() {
    this.initialize();
  },
  components: {
    AssignRoleUsersDialog,
  },
  computed: {
    roleUsers() {
      return this.$store.getters.getRoleUsers;
    },
    viewRole() {
      return this.$store.getters.getViewRole;
    },
    dialogAssignUsers: {
      get() {
        return this.$store.getters.getDialogAssignUsers;
      },
      set(value) {
        this.$store.commit("setDialogAssignRoleUsers", value);
      },
    },
  },
  methods: {
    async initialize() {
      this.roleUuid = this.$route.params.roleUuid;
      await this.$store.dispatch("fetchViewRole", this.roleUuid);
    },
    removeFromRole(userDetails) {
      // console.log(userDetails)
      this.userTemp=userDetails
      // console.log(this.userTemp);
      this.dialogDelete=true
    },

    assignUsers() {
      this.dialogAssignUsers = true;
    },
    closeAssignUsers() {
      this.dialogAssignRoleUsers = false;
      this.selectedUsers = [];
    },
    async deleteItemConfirm(user_id) {
      // console.log(user_id);
      await this.$store
        .dispatch("removeUserFromRole", {
          userId: user_id,
          roleUuid: this.viewRole.uuid,
        }).then(()=>{
          this.dialogDelete=false
          this.userTemp=null
        })
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.user = {};
      });
    },
  },
};
</script>

<style></style>
